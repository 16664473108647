require('./bootstrap');

import _ from 'lodash';
import Alpine from 'alpinejs';
import mask from '@alpinejs/mask';
import flatpickr from "flatpickr";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import TomSelect from 'tom-select/dist/js/tom-select.complete';
import {createPopper} from '@popperjs/core';

Alpine.plugin(mask);


window.Alpine = Alpine;
window.flatpickr = flatpickr;
window.monthSelectPlugin = monthSelectPlugin;
window.TomSelect = TomSelect;
window.createPopper = createPopper;

window.Alpine.start();